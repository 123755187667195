div.gallery {
    border: 3px solid #ccc;
    border-radius: 2px;
    margin-top: 5%;
}

div.gallery:hover {
    border: 3px solid #777;
}

div.gallery img {
    width: 100%;
    height: auto;
}

div.desc {
    padding: 15px;
    text-align: left;
}

div.likecount {
    margin-top: 2vh;
    padding: 5px;
    text-align: left;
}

div.likebuttondiv {
    margin-top: 2vh;
    padding: 5px;
    text-align: left;
}

* {
    box-sizing: border-box;
}

.likebuttons {
    cursor: pointer;
    color: black;
    border: none;
}

.responsive {
    /*padding: 0 6px;*/
    /*ORIGINAL VALUE*/
    padding: 6px 6px;
    float: left;
    /*width: 24.99999%;*/
    width: 100%;
    /*33% for 3 files in single row*/
    background-color: white;
}

@media only screen and (max-width: 700px) {
    .responsive {
        width: 49.99999%;
        margin: 6px 0;
    }
}

@media only screen and (max-width: 500px) {
    .responsive {
        width: 100%;
    }
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}