.imageContainer {
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    background-image: url('../assets/images/pc.jpg');
    background-size: cover;
    background-position: top;
    background-origin: inherit;
    background-repeat: no-repeat;
    z-index: -100;
}

.formContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}
.inputContainer{
    display: flex;
    flex-direction: row;
}

.inputContainer label{
    width: 200px;
}

.container {
    top: 48vh;
}

@media only screen and (max-width: 500px) {
    .container {
        top: 25vh;
    }
    .imageContainer {
        background-image: url('../assets/images/mobileportrait.jpg') !important;
        background-size: contain;
    }
    .inputContainer{
        display: flex;
        flex-direction: column;
        margin-left: 3%;
    }
    .inputContainer label{
        width: auto;
    }
    .inputContainer input, .inputContainer select {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 10px;
    }
    .formContainer form{
        left: 50px;
        top: 30% !important;
        right: 50px;
    }
    .button{
        position: absolute;
        top: 60vh !important;
    }
}
