body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.imageContainer {
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    background-image: url('./assets/images/pc.jpg');
    background-size: contain;
    background-position: top;
    background-origin: inherit;
    background-repeat: no-repeat;
    z-index: -100;
    background-color: black;
    background-position: center;
}

.form-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

@media only screen and (max-width: 500px) {
    .imageContainer {
        background-image: url('./assets/images/mobileportrait.jpg') !important;
        background-size: contain;
        background-position: initial;
    }
}

#warning {
    position: absolute;
    top: 40vh;
    left: 0vh;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 2vh;
    font-weight: bold;
}

#seeMyMemes {
    position: absolute;
    top: 75vh;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 2vh;
    font-weight: bold;
}

#seeAllMemes {
    position: absolute;
    top: 84vh;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 2vh;
    font-weight: bold;
}

#uploadButton {
    position: absolute;
    top: 66vh;
}

@media only screen and (max-width: 600px) {
    #warning {
        position: absolute;
        top: 23vh;
        left: 0vh;
        color: white;
        width: 100%;
        text-align: center;
        font-size: 2vh;
        font-weight: bold;
    }

    #seeMyMemes {
        position: absolute;
        top: 70vh;
        width: 100%;
        text-align: center;
        color: white;
        font-size: 2vh;
        font-weight: bold;
    }
    
    #seeAllMemes {
        position: absolute;
        top: 75vh;
        width: 100%;
        text-align: center;
        color: white;
        font-size: 2vh;
        font-weight: bold;
    }

    #uploadButton {
        position: absolute;
        top: 60vh;
    }
}

div.gallery {
    border: 1px solid #ccc;
    margin-top: 5%;
}

div.gallery:hover {
    border: 1px solid #777;
}

div.gallery img {
    width: 100%;
    height: auto;
}

div.desc {
    padding: 15px;
    text-align: center;
}

* {
    box-sizing: border-box;
}

.responsive {
    padding: 0 6px;
    float: left;
    /*width: 24.99999%;*/
    width: 100%;
    /*33% for 3 files in single row*/
}

@media only screen and (max-width: 700px) {
    .responsive {
        width: 49.99999%;
        margin: 6px 0;
    }
}

@media only screen and (max-width: 500px) {
    .responsive {
        width: 100%;
    }
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}